<template>
  <div class="secondLevel">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>二级分类</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <el-button
        type="primary"
        @click="addClassADialogVisible = true"
        v-if="$store.state.powerList.indexOf('hotel:type:list:insert')"
        >添加分类</el-button
      >
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" border>
      <!-- <el-table-column type="selection" width="55"></el-table-column> -->
      <el-table-column align="center" prop="dept" label="排序">
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="onChangeSort(row)"
            >{{ row.dept }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="id" label="分类ID"> </el-table-column>
      <el-table-column prop="cateName" label="分类名称"> </el-table-column>
      <el-table-column prop="addTime" label="分类ID"> </el-table-column>
      <el-table-column align="center" prop="statusStr" label="状态">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            v-if="row.status === 2"
            type="primary"
            @click="changeStatus(row.id, 1)"
            >上架</el-button
          >
          <el-button
            size="mini"
            v-if="row.status === 1"
            type="primary"
            @click="changeStatus(row.id, 2)"
            >下架</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="onDelCate(row)"
            v-if="$store.state.powerList.indexOf('hotel:type:list:delete')"
            >删除</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="onEditCate(row)"
            v-if="$store.state.powerList.indexOf('hotel:type:list:update')"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      title="添加分类"
      :visible.sync="addClassADialogVisible"
      width="30%"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="分类名称">
          <el-input
            placeholder="请输入要添加的分类名称"
            v-model="addform.cateName"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addClassADialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onAddCateOk">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      title="编辑分类"
      :visible.sync="editDialogVisible"
      width="30%"
    >
      <el-form ref="form" label-width="80px">
        <!-- <el-form-item label="当前分类ID">
          <el-input v-model="editform.cateId" disabled></el-input>
        </el-form-item> -->
        <el-form-item label="分类名称">
          <el-input v-model="editform.cateName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onEditCateOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  hotelCateList,
  addHotel,
  editHotelCate,
  delHotelCate,
  editSort,
  changeStatus,
} from "../../api/hotel";
export default {
  name: "secondLevel",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      tableData: [],
      addClassADialogVisible: false,
      editDialogVisible: false,
      addform: {
        cateName: "",
        fatherId: this.id,
        cateId: 0,
      },
      editform: {
        cateId: "",
        cateName: "",
        fatherId: Number(this.id),
      },
      list: {
        pageSize: 5,
        currentPage: 1,
        fatherId: Number(this.id),
      },
      pagination: {},
    };
  },
  created() {
    this.getSubClassificationList();
  },
  methods: {
    changeStatus(cateId, type) {
      this.$confirm("是否操作这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            cateId: cateId,
            status: type,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getSubClassificationList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleSizeChange(size) {
      this.list.pageSize = size;
      this.getSubClassificationList();
    },
    handleCurrentChange(Current) {
      this.list.currentPage = Current;
      this.getSubClassificationList();
    },
    async getSubClassificationList() {
      const { data } = await hotelCateList(this.list);
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.pagination = data.pagination;
      this.tableData = data.list;
    },
    onInput(value) {
      var reg = /^-?[1-9]\d*$/;
      if (!reg.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },
    async handleInputConfirm(row) {
      const { data } = await editSort({
        cateId: row.id,
        dept: row.dept,
      });
      row.inputVisible = false;
      this.getSubClassificationList();
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    async onAddCateOk() {
      if (this.addform.cateName === "") {
        this.$message({
          type: "error",
          message: "请把数据填写完整!",
        });
        return;
      }
      const { data } = await addHotel(this.addform);
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: "添加成功!",
        });
      } else {
        this.$message({
          type: "error",
          message: "添加失败!",
        });
      }
      this.addClassADialogVisible = false;
      this.getSubClassificationList();
      this.addform.cateName = "";
    },
    onEditCate(row) {
      this.editDialogVisible = true;
      this.editform.cateId = row.id;
      this.editform.cateName = row.cateName;
    },
    async onEditCateOk() {
      if (this.editform.cateName === "") {
        this.$message({
          type: "error",
          message: "请把数据填写完整!",
        });
        return;
      }
      const { data } = await editHotelCate(this.editform);
      console.log(data);
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: "编辑成功!",
        });
      } else {
        this.$message({
          type: "error",
          message: "编辑失败!",
        });
      }
      this.editDialogVisible = false;
      this.getSubClassificationList();
    },
    onDelCate(row) {
      console.log(row);
      this.$confirm("是否删除这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delHotelCate({
            cateId: row.id,
          });
          this.getSubClassificationList();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.secondLevel {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
  }
  /deep/.el-table {
    margin-top: 50px;
    .el-table__header-wrapper {
      table {
        th {
          text-align: center;
        }
      }
    }
    .el-table__body-wrapper {
      table {
        td {
          text-align: center;
        }
      }
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>